/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import {
  type ColumnDef,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React from "react"
import {
  type CoreSessionResponseEntity,
  type CoreSessionStatus,
  type ProjectResult,
  ProjectStatus,
  type UserResult,
} from "@garden-io/platform-api-types"
import { Button, ButtonLink } from "../../components/button"
import { EmptyState } from "../../components/empty-state"
import { ErrorState } from "../../components/error-state"
import { Filter, type FilterConfig, useResetFilter } from "../../components/filter"
import { ArrowUp, ChevronRight, Command, Icon } from "../../components/icons"
import { LoadingIndicator } from "../../components/loading-indicator"
import { Page, type ProjectPageProps } from "../../components/page"
import { ProjectNotConnectedCard } from "../../components/project-not-connected-card"
import { Table } from "../../components/table"
import { InlineCode, MonospacedText, Text } from "../../components/text"
import { tokens } from "../../design-system"
import { useInfiniteApiQuery } from "../../queries/index"
import { InfoTooltip } from "../../ui-kit"
import { useUsersOptions } from "../../utils/hooks/use-users-options"
import { useVirtualLinkClickHandler } from "../../utils/hooks/virtual-link-click-handler"
import { formatTimeSince, parseQueryParam, parseQueryParamArray } from "../../utils/util"
import { CommandStatusTag, getCommandDetailUrl, getCommandString } from "./shared"

const Cell = ({ children, title }: { children: React.ReactNode; title?: string }) => (
  <Text title={title}>{children}</Text>
)

const namespaceInfoText = `The Garden namespace that the command ran in.`

const columns: ColumnDef<CoreSessionResponseEntity>[] = [
  {
    id: "command",
    header: "Command",
    accessorFn: (row) => row.command,
    cell: (info) => {
      const session = info.row.original
      const command = getCommandString(session.command, session.cliArgs)
      return (
        <MonospacedText color="primary" title={command}>
          {command}
        </MonospacedText>
      )
    },
  },
  {
    id: "environment",
    header: "Environment",
    accessorFn: (row) => row.environment.name,
    cell: (info) => <Cell>{info.getValue<string>()}</Cell>,
  },
  {
    id: "namespace",
    header: "Namespace",
    accessorFn: (row) => row.namespace.name,
    cell: (info) => <Cell>{info.getValue<string>()}</Cell>,
  },
  {
    id: "version",
    header: "Version",
    accessorFn: (row) => row.clientVersion,
    cell: (info) => {
      const untruncatedVersion = info.getValue<string>().repeat(10)
      let version = untruncatedVersion
      // Allows for "0.13.123', for example.
      if (untruncatedVersion.length > 8) {
        version = untruncatedVersion.slice(0, 8) + "..."
      }
      return <Cell title={untruncatedVersion}>v{version}</Cell>
    },
  },
  {
    id: "user",
    header: "User",
    accessorFn: (row) => row.user.name,
    cell: (info) => <Cell>{info.getValue<string>()}</Cell>,
  },
  {
    id: "createdAt",
    header: "Started",
    accessorFn: (row) => row.createdAt,
    cell: (info) => <Cell>{formatTimeSince(info.getValue<string>(), " ago")}</Cell>,
  },
  {
    id: "status",
    header: "Status",
    accessorFn: (row) => row.status,
    cell: (info) => {
      const session = info.row.original
      const meta = info.table.options.meta as any // The lib types are incorrect here
      const activeSession = meta?.activeSession
      // Using optional chaining and fallback since type is any
      const activeSessionId = activeSession?.id || ""

      return (
        <Cell>
          <CommandStatusTag activeSessionId={activeSessionId} session={session} styles={{ width: "100%" }} />
        </Cell>
      )
    },
  },
  {
    id: "controls",
    header: "",
    accessorFn: (row) => row,
    cell: () => {
      return <Icon Component={ChevronRight} title={null} />
    },
  },
]

const useQueryFilters = ({ user, project }: { user: UserResult; project: ProjectResult }) => {
  const usersOptions = useUsersOptions({ user, organization: project.organization })

  const filterConfig: FilterConfig[] = React.useMemo(() => {
    return [
      {
        label: "User",
        queryParamName: "userId",
        options: usersOptions.options,
      },
      {
        label: "Environment",
        queryParamName: "environmentId",
        options:
          project?.environments.map((environment) => ({
            key: environment.id,
            label: environment.name,
          })) ?? [],
      },
      {
        label: "Status",
        queryParamName: "status",
        options: [
          { key: "success", label: "Succeeded" },
          { key: "error", label: "Failed" },
        ],
      },
    ]
  }, [usersOptions.options, project?.environments])

  const resetFilters = useResetFilter(filterConfig)

  return {
    component: <Filter config={filterConfig} isLoading={usersOptions.isLoading} />,
    resetFilters,
  }
}

export const CommandsList = ({ user, project }: ProjectPageProps) => {
  const searchParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(searchParams)
  // The active session refers to the most recent active session in watch mode, if any.
  const activeSession = user?.activeCoreSession

  const queryFilters = useQueryFilters({ user, project })

  const virtualLinkClickHandler = useVirtualLinkClickHandler()

  const isFilterActive = queryParams.userId || queryParams.environmentId || queryParams.status

  const listSessionsQuery = useInfiniteApiQuery((api) =>
    api.sessions.listCoreSessions({
      projectId: project.id,
      environmentId: parseQueryParam("environmentId"),
      userIds: parseQueryParamArray("userId"),
      statuses: parseStatusValueParam(),
    })
  )

  const [sorting, setSorting] = React.useState<SortingState>([{ id: "createdAt", desc: true }])
  const table = useReactTable({
    data: listSessionsQuery.flatData ?? [],
    meta: {
      activeSession,
    },
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  })

  const isQueryEmpty = listSessionsQuery.isEmpty
  // if query is empty filters should only be shown if they had been applied
  const showFilters = isFilterActive || !isQueryEmpty
  return (
    <Page scope="project" name="commands-list" title="Commands">
      {showFilters && <div css={{ marginBottom: tokens.spacing[12] }}>{queryFilters.component}</div>}

      {(() => {
        if (listSessionsQuery.error) {
          return (
            <ErrorState
              title="Something went wrong"
              description={
                listSessionsQuery.error.apiErrorMessage ??
                "There was an error loading the commands. If the problem persists, please contact support."
              }
            />
          )
        }
        if (listSessionsQuery.isLoading) {
          return (
            <LoadingIndicator
              styles={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                animation: "none",
              }}
            />
          )
        }

        if (project?.status !== ProjectStatus.Connected) {
          return <ProjectNotConnectedCard project={project} />
        }

        if (isQueryEmpty) {
          return (
            <EmptyState
              title="There is no commands history to display."
              Icon={Command}
              description={
                isFilterActive ? (
                  <ButtonLink onClick={queryFilters.resetFilters}>Reset all filters</ButtonLink>
                ) : (
                  <Text styles={{ textAlign: "center" }}>
                    It doesn't look like you've run any Garden commands yet. Please open your terminal and try running{" "}
                    <InlineCode>garden build</InlineCode> to get started.
                  </Text>
                )
              }
            />
          )
        }

        return (
          <>
            <div
              css={{
                overflowX: "auto",
              }}
            >
              <Table
                css={{
                  "tr:hover": {
                    backgroundColor: tokens.colors["element-100"],
                    cursor: "pointer",
                  },
                  "tr:hover .link-indicator": {
                    display: "inherit",
                  },
                }}
              >
                <thead css={{ backgroundColor: tokens.colors["element-100"] }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const sorted = header.column.getIsSorted()
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            css={{
                              ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
                            }}
                          >
                            <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: tokens.spacing[4],
                                }}
                              >
                                <Text color={sorted ? "primary" : "secondary"}>
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                </Text>
                                {header.id === "namespace" && (
                                  <InfoTooltip id={header.id} infoText={namespaceInfoText} />
                                )}
                              </div>
                              <Icon
                                color="text-secondary"
                                Component={ArrowUp}
                                title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
                                css={{
                                  visibility: sorted ? "visible" : "hidden",
                                  transform: sorted === "desc" ? "rotate(180deg)" : "none",
                                }}
                              />
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody css={{ "tr:last-of-type td": { borderBottom: "none" } }}>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      onClick={virtualLinkClickHandler(
                        getCommandDetailUrl({ projectId: project.id, sessionId: row.original.id })
                      )}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          css={{
                            borderBottom: `1px solid ${tokens.colors["border-secondary"]}`,
                            maxWidth: "20rem",
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div css={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 8 * 3 }}>
              <Button
                onClick={() => listSessionsQuery.fetchNextPage()}
                state={listSessionsQuery.isFetching ? "loading" : undefined}
                disabled={!listSessionsQuery.hasNextPage}
              >
                {listSessionsQuery.hasNextPage ? "Load more" : "All commands loaded"}
              </Button>
            </div>
          </>
        )
      })()}
    </Page>
  )
}

/**
 *
 * ATTENTION: this function is an edge case, we should get rid of it
 *
 * Parse query param from URL and converts to bool.
 *
 * Returns undefined if value is '*' since that's interpreted as an empty param.
 */
const parseStatusValueParam = () => {
  const queryParams = new URLSearchParams(window.location.search)

  const values = queryParams.getAll("status") as CoreSessionStatus[]

  // We need to add this hack here to support the new multiselect filter
  // User can select status = success AND status = error, in that case we should just show all
  return values.length === 2 ? undefined : values
}
