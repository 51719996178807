/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { isEmpty } from "lodash"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { EphemeralClustersAuthError } from "../components/ephemeral-clusters/auth-error"
import { LoadingIndicator } from "../components/loading-indicator"
import { DefaultProjectPageRedirect, ProjectPageWrapper } from "../components/page"
import { useEnv, useFeatureFlags, useProjectContext, useUser } from "../contexts"
import { tokens } from "../design-system"
import { Activity, activityPageOptions } from "../pages/activity"
import { AECActivity } from "../pages/automatic-environment-cleanup/aec-activity"
import { AECSettings } from "../pages/automatic-environment-cleanup/aec-settings"
import { aecPageOptions } from "../pages/automatic-environment-cleanup/shared"
import { CliLogin, CliLoginSuccess } from "../pages/cli-login"
import { CloudBuilderActiveBuilders, cloudBuilderActiveBuildersOptions } from "../pages/cloud-builder/active-builders"
import { CloudBuilderOrganization } from "../pages/cloud-builder/cloud-builder-organization"
import { CloudBuilderOverview } from "../pages/cloud-builder/cloud-builder-overview"
import { cloudBuilderOrganizationOptions, cloudBuilderOverviewOptions } from "../pages/cloud-builder/shared"
import { CommandDetail, commandsTab } from "../pages/commands/command-detail"
import { CommandsList } from "../pages/commands/commands-list"
import { FeatureFlags, featureFlagsPageOptions } from "../pages/feature-flags"
import { Authentication as FreeTierAuthentication } from "../pages/free-tier/authentication"
import { Groups, groupsPageOptions } from "../pages/groups"
import { Index } from "../pages/index"
import { Insights, insightsPageOptions } from "../pages/insights/insights"
import { InsightsDetail } from "../pages/insights/insights-detail"
import { LivePage, livePageOptions } from "../pages/live"
import { CreateOrganization } from "../pages/organization/create-organization"
import { Organization, organizationsPageOptions } from "../pages/organization/organization"
import { SwitchOrganization } from "../pages/organization/switch-organization-page"
import { PreviewEnv, previewEnvTab } from "../pages/preview-env"
import { Privacy } from "../pages/privacy"
import { ProjectDashboard } from "../pages/project-dashboard"
import { Projects, projectsPageOptions } from "../pages/projects"
import { Secrets, secretsPageOptions } from "../pages/secrets"
import { EnvironmentsSettings } from "../pages/settings/environment-settings"
import { ProjectSettings } from "../pages/settings/project-settings"
import { ShortLinkHandler } from "../pages/short-link-handler"
import { Team, teamPageOptions } from "../pages/team/team"
import { Theme, themePageOptions } from "../pages/theme"
import { Usage, usagePageOptions } from "../pages/usage/usage"
import { Workflows, workflowsPageOptions } from "../pages/workflows"
import { Demos } from "./demos"
import { LayoutSelector } from "./layout"
import { LegacyRedirects } from "./legacy-redirects"
import { UserNavigationScope, usePersistedScope } from "./user-navigation-scope"

// this url should never change, because it's hardcoded on the namespaces side
const EPHEMERAL_CLUSTERS_AUTH_ERROR_PATH = "/ephemeral-clusters/auth-error"

export const Router = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const user = useUser()
  const env = useEnv()
  const projectQuery = useProjectContext()
  const theming = useFeatureFlags("theming")
  const isCloudBuilderEnabled = env.cloudBuilderEnabled

  // Keep track of the current application scope of this user (the selected project or organization).
  usePersistedScope(user)

  // Retrieve the current `organizationId` from the URL path (if any).

  // Show a spinner while loading the authenticated user, or while loading the environment.
  // Due to a quirk with the JWT authentication mechanism, we must check both the authenticated
  // state and separately fetch the user (the latter of which which can fail).
  if (isEmpty(env) || (isLoggedIn && !user)) {
    return <LoadingIndicator styles={{ padding: tokens.spacing[32] }} />
  }

  const isFreeTier = env.freeTierEnabled

  return (
    <LayoutSelector>
      <Routes>
        <Route
          path="/"
          element={user ? <UserNavigationScope user={user} /> : isFreeTier ? <FreeTierAuthentication /> : <Index />}
        />
        <Route path="/clilogin/success" element={<CliLoginSuccess />} />
        <Route path="/clilogin/:cliPort" element={<CliLogin />} />
        <Route path={EPHEMERAL_CLUSTERS_AUTH_ERROR_PATH} element={<EphemeralClustersAuthError />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/demos/*" element={<Demos />} />
        {user ? (
          <React.Fragment>
            {isFreeTier
              ? [
                  // These are global pages in free tier, its page components take props conforming to `GlobalPageProps`.
                  { path: "/switch-organization", element: SwitchOrganization },
                  { path: "/create-organization", element: CreateOrganization },
                ].map((route) => <Route key={route.path} path={route.path} element={<route.element user={user} />} />)
              : null}

            {[
              // These are organization-scoped pages, its page components take props conforming to `OrganizationPageProps`.
              { path: organizationsPageOptions.getPath(":organizationId"), element: Organization },
              { path: projectsPageOptions.getPath(":organizationId"), element: Projects },
              { path: teamPageOptions.getPath(":organizationId"), element: Team },
              !isFreeTier ? { path: usagePageOptions.getPath(":organizationId"), element: Usage } : null,
              !isFreeTier ? { path: groupsPageOptions.getPath(":organizationId"), element: Groups } : null,
              !isFreeTier ? { path: featureFlagsPageOptions.getPath(":organizationId"), element: FeatureFlags } : null,
              theming?.enabled ? { path: themePageOptions.getPath(), element: Theme } : null,
              isCloudBuilderEnabled
                ? {
                    path: cloudBuilderOrganizationOptions.getPath(":organizationId"),
                    element: CloudBuilderOrganization,
                  }
                : null,
              isCloudBuilderEnabled
                ? {
                    path: cloudBuilderActiveBuildersOptions.getPath(":organizationId"),
                    element: CloudBuilderActiveBuilders,
                  }
                : null,
            ].map((route) => {
              if (!route) {
                return null
              }

              const organization = user.meta.currentOrganization
              if (!organization) {
                throw new Error("Organization not available.")
              }

              return route ? (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.element organization={organization} user={user} />}
                />
              ) : null
            })}
            {[
              // These are project-scoped pages, its page components take props conforming to `ProjectPageProps`.
              { path: "/projects/:projectId", element: DefaultProjectPageRedirect },
              { path: livePageOptions.getPath(":projectId"), element: LivePage },
              { path: "/projects/:projectId/commands", element: CommandsList },
              { path: commandsTab.getPath(":projectId", ":commandId"), element: CommandDetail },
              { path: workflowsPageOptions.getPath(":projectId"), element: Workflows },
              { path: aecPageOptions.getPath(":projectId"), element: AECActivity },
              { path: "/projects/:projectId/cleanup-runs/settings", element: AECSettings },
              { path: activityPageOptions.getPath(":projectId"), element: Activity },
              { path: insightsPageOptions.getPath(":projectId"), element: Insights },
              {
                path: "/projects/:projectId/insights/environments/:environmentId/actionKinds/:actionKind/actionNames/:actionName",
                element: InsightsDetail,
              },
              { path: secretsPageOptions.getPath(":projectId"), element: Secrets },
              { path: "/projects/:projectId/settings", element: ProjectSettings },
              { path: "/projects/:projectId/settings/environments", element: EnvironmentsSettings },
              { path: previewEnvTab.getPath(":projectId"), element: PreviewEnv },
              { path: "/projects/:projectId/environments/:environmentId/dashboard", element: ProjectDashboard },
              isCloudBuilderEnabled
                ? { path: cloudBuilderOverviewOptions.getPath(":projectId"), element: CloudBuilderOverview }
                : null,
            ].map((route) => {
              if (!route) {
                return null
              }
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProjectPageWrapper
                      RouteElement={route.element}
                      user={user}
                      projectQuery={projectQuery}
                      isFreeTier={isFreeTier}
                    />
                  }
                />
              )
            })}
            <Route path="/go/:shortLink" element={<ShortLinkHandler />} />
            <Route path="/go/command/:shortLink" element={<ShortLinkHandler />} />
            <Route path="*" element={<LegacyRedirects />} />
          </React.Fragment>
        ) : (
          <Route
            path="*"
            element={<Navigate to={`/?redirectTo=${window.location.pathname}${window.location?.search ?? ""}`} />}
          />
        )}
      </Routes>
    </LayoutSelector>
  )
}
