/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { Navigate, useMatch } from "react-router-dom"
import { NotFound } from "../pages/not-found"
import { getUrlWithParams } from "../queries/index"

// These must be kept around due to old links in VCS systems or old versions of Core.
// TODO Set up a list of redirect paths in nginx.
const legacyNamespaceStackPath = "/projects/:projectId/environments/:environmentId/namespaces/:namespaceId/stack"
const legacyNamespaceWorkflowRunsPath = "/projects/:projectId/environments/:environmentId/namespaces/:namespaceId/runs"

// Redirect from old nested environment & namespace structure URLs to the new commands or workflow runs pages.
// This should be considered legacy; we can remove it once all VCS systems have transistioned and Core no longer uses old paths.
export const LegacyRedirects = () => {
  const stackMatch = useMatch(legacyNamespaceStackPath)
  const workflowRunsMatch = useMatch(legacyNamespaceWorkflowRunsPath)
  if (stackMatch) {
    return <Navigate to={{ pathname: `/projects/${stackMatch.params.projectId}` }} />
  } else if (workflowRunsMatch) {
    // Note that we must redirect to workflow runs for older PRs, as links are not updated in the VCS systems.
    const searchParams = new URLSearchParams(window.location.search)
    const workflowId = searchParams.get("id")
    const search = getUrlWithParams("", {
      id: workflowId,
      environmentId: workflowRunsMatch.params.environmentId ?? "*",
      userId: "*", // Ensure we don't hide the run due to defaulting to the current user, who may not be the same as the workflow's.
    })
    return <Navigate to={{ pathname: `/projects/${workflowRunsMatch.params.projectId}/workflows`, search }} />
  } else {
    return <NotFound />
  }
}
