/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

// The main Sentry import must appear before the others
import * as Sentry from "@sentry/react"
import { Integrations as TracingIntegrations } from "@sentry/tracing"
import { type Env } from "@garden-io/platform-api-types"
import { isApiError } from "./api/utils"
import { getReportConfig } from "./utils/util"

// Helpers for preventing typos when setting Sentry tags
type SentryTag = "customer" | "installation_type" | "license_type"
function setSentryTags(tags: { [key in SentryTag]: string | undefined }) {
  for (const [key, value] of Object.entries(tags)) {
    Sentry.setTag(key, value || "")
  }
}

export const configureErrorHandling = (env: Env) => {
  // Log global errors not caught by the error boundary component.
  window.addEventListener("error", (errorEvent) => {
    const message = errorEvent.error?.toString() ?? errorEvent.message ?? "Unknown error"
    const position = `line: ${errorEvent.lineno}; column ${errorEvent.colno} in ${errorEvent.filename}`
    console.error(`Uncaught global error: ${message} at ${position} (${document.location.href})`)
    if (env.enableReporting) {
      try {
        Sentry.captureException(errorEvent)
      } catch (err) {
        if (isApiError(err)) {
          console.error("Reporting exception failed with error:", err.message)
        } else {
          throw err
        }
      }
    }
  })

  const reportConfig = getReportConfig(env)
  if (reportConfig) {
    try {
      Sentry.init({
        dsn: reportConfig.sentryDsn,
        tunnel: "/sentry",
        release: reportConfig.version,
        environment: process.env.NODE_ENV,
        integrations: [
          new TracingIntegrations.BrowserTracing({
            tracingOrigins: ["localhost", env.mainHostname, /^\//],
            beforeNavigate: (context) => {
              return {
                ...context,
                // Replace UUIDs in URLs with :uid and IDs with :id. This ensures requests to,
                // say, "users/5" and "users/6" are treated as the same endpoint.
                // TODO: Considering using Sentry's React Router integration instead, see:
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                name: window.location.pathname
                  .replace(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi, "/:uid")
                  .replace(/\/\d+/gi, "/:id"),
              }
            },
          }),
        ],
        tracesSampler: (_samplingContext) => {
          // Set a higher for sample rate for pre-releases in staging env. This ensures we
          // catch perf regressions before we release them.
          if (reportConfig.version.includes("-") && reportConfig.customer === "garden-staging") {
            return 0.5
          }
          return 0.05
        },
      })
      setSentryTags({
        customer: reportConfig.customer,
        installation_type: reportConfig.installationType,
        license_type: reportConfig.licenseType,
      })
    } catch (err) {
      if (isApiError(err)) {
        console.error("Initializing error reporting failed with error:", err.message)
      } else {
        throw err
      }
    }
  }
}
