/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { Markdown } from "../components/markdown"
import { Page } from "../components/page"
import { tokens } from "../design-system"

export const Privacy = () => (
  <Page
    scope="global"
    name="privacy"
    title="Privacy Policy"
    breadcrumbs={[{ title: "Privacy Policy", route: "/privacy" }]}
    styles={{
      marginBottom: tokens.spacing[32],
    }}
  >
    <Markdown url="./content/privacy.md" />
  </Page>
)
