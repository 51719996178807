/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { useParams } from "react-router-dom"
import { Page, type ProjectPageProps } from "../components/page"
import { Text } from "../components/text"

export const ProjectDashboard = ({ project }: ProjectPageProps) => {
  const { environmentId } = useParams<{ environmentId: string }>()
  const environment = project.environments.find((env) => env.id === environmentId)

  const breadcrumbs = project
    ? [
        { title: "Projects", route: `/projects` },
        { title: project.name, route: `/projects/${project.id}` },
        { title: environment?.name || "", route: `/projects/${project.id}/environments/${environment?.id}` },
      ]
    : []

  return (
    <Page
      scope="project"
      name="project-dashboard"
      title="Dashboard"
      breadcrumbs={breadcrumbs}
      styles={{
        justifyContent: "center",
        alignItems: "center",
        height: 500,
      }}
    >
      <Text>Dashboard contents will be here (and it will have a full page width)</Text>
    </Page>
  )
}
