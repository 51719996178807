/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import {
  type ColumnDef,
  type Table as TableDataType,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React from "react"
import { match } from "ts-pattern"
import {
  type ActionRuntime,
  type ActionRuntimeKind,
  type ActionRuntimeRemotePlugin,
  ActionRuntimeSummary,
  ActionState,
  type ListTaskResultsResult,
} from "@garden-io/platform-api-types"
import { actionStateToVariantProps, normalizeActionState } from "../../components/actions-list"
import { Button, LinkButton } from "../../components/button"
import { EmptyState } from "../../components/empty-state"
import { ErrorState } from "../../components/error-state"
import { Activity, ArrowDown, ArrowUp, ChevronRight, Cloud, Icon } from "../../components/icons"
import { Link } from "../../components/link"
import { LoadingIndicator } from "../../components/loading-indicator"
import { Page, type ProjectPageProps } from "../../components/page"
import { Table } from "../../components/table"
import { Tag, TagWithText } from "../../components/tag"
import { MonospacedText, Text } from "../../components/text"
import { TimeIndicator } from "../../components/time-indicator"
import { tokens } from "../../design-system"
import { commandsTab } from "../../pages/commands/command-detail"
import { useInfiniteApiQuery } from "../../queries"
import { InfoTooltip } from "../../ui-kit"
import { useVirtualLinkClickHandler } from "../../utils/hooks/virtual-link-click-handler"
import { formatDurationMsec } from "../../utils/util"
import { cloudBuilderOrganizationOptions, isPreferredRuntime } from "./shared"

export const formatPercent = (value: number) => `${(value * 100).toFixed(0)}%`

const getColumns = (): ColumnDef<ListTaskResultsResult>[] => [
  {
    id: "builds",
    header: "Builds",
    accessorFn: (row) => row.id,
    cell: ({ row }) => <MonospacedText>{row.original.actionName}</MonospacedText>,
  },
  {
    id: "duration",
    header: "Duration",
    accessorFn: (row) => (row.durationMsec ? formatDurationMsec(row.durationMsec) : "N/A"),
  },
  {
    id: "user",
    header: "Triggered by",
    accessorFn: (row) => row.user.name,
  },
  {
    id: "createdAt",
    header: "Triggered at",
    accessorFn: (row) => row.createdAt,
    cell: ({ row }) => <TimeIndicator date={row.original.createdAt} color="primary" />,
  },
  {
    id: "cloudBuilder",
    header: "Runtime",
    accessorFn: (row) => determineAcceleration(row.actionDetail.runtime),
    cell: ({ row }) => {
      const { runtime } = row.original.actionDetail
      const isPreferred = runtime ? isPreferredRuntime({ actual: runtime.actual, preferred: runtime.preferred }) : false
      const label = getRuntimeLabel(runtime?.actual)

      const fallbackTitle = isPreferred
        ? undefined
        : row.original.actionDetail.runtime?.fallbackReason ?? "Failed to build in Cloud builder"

      if (isPreferred) {
        return (
          <Text color={isPreferred ? "primary" : "text-error"} title={fallbackTitle}>
            {label}
          </Text>
        )
      } else {
        return (
          <TagWithText weight="semi-bold" tagProps={{ variant: "failure", title: fallbackTitle }} title={fallbackTitle}>
            {label}
          </TagWithText>
        )
      }
    },
  },
  {
    id: "status",
    header: "Status",
    accessorFn: (row) => row.actionState,
    cell: ({ row }) => {
      const state = row.original.actionState
      const variantProps = actionStateToVariantProps(normalizeActionState(state))
      return <Tag variant={variantProps.variant} label={variantProps.label} styles={{ flex: 1, height: 20 }} />
    },
  },
  {
    id: "controls",
    header: "",
    accessorFn: (row) => row,
    cell: () => {
      return <Icon Component={ChevronRight} title={null} />
    },
  },
]

export function getRuntimeLabel(runtime?: ActionRuntimeKind): string {
  switch (runtime?.kind) {
    case "local":
      return "Local build"
    case "remote":
      switch (runtime.type) {
        case "garden-cloud":
          return "Cloud builder"
        case "plugin":
          return `Plugin "${(runtime as ActionRuntimeRemotePlugin).pluginName}"`
        default:
          return runtime.type
      }
    default:
      return "Unknown"
  }
}

const determineAcceleration = (runtime?: ActionRuntime) => {
  if (!runtime) {
    return false
  }
  if (runtime.actual.kind === "remote" && runtime.actual.type === "garden-cloud") {
    return true
  }
  if (
    runtime.preferred.kind === "remote" &&
    runtime.preferred.type === "garden-cloud" &&
    runtime.actual.kind !== "remote"
  ) {
    return false
  }
  return false
}

export const CloudBuilderOverview = ({ project }: ProjectPageProps) => {
  const actionsQuery = useInfiniteApiQuery((api) =>
    api.projects.listActions(project.id, {
      runtime: ActionRuntimeSummary.RemoteGardenCloud,
      actionStates: [
        ActionState.Unknown,
        ActionState.GettingStatus,
        ActionState.NotReady,
        ActionState.Processing,
        ActionState.Ready,
        ActionState.Unknown,
      ] satisfies Exclude<ActionState, ActionState.Cached>[],
    })
  )

  const table = useReactTable({
    data: actionsQuery.flatData ?? [],
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <Page
      name="cloud-builder"
      title="Cloud builder"
      scope="project"
      styles={{ gap: tokens.spacing[12] }}
      action={
        <LinkButton to={cloudBuilderOrganizationOptions.getPath(project.organization.id)} Icon={Activity}>
          Usage & builders
        </LinkButton>
      }
    >
      {match(actionsQuery)
        .with({ status: "loading" }, () => <LoadingIndicator />)
        .with({ status: "error" }, () => (
          <ErrorState
            title="Failed to load Cloud builder"
            description="Failed to load Cloud builder. Please try again later."
          />
        ))
        .with({ status: "success", isEmpty: true }, () => (
          <EmptyState
            title="Garden Cloud builder needs to be enabled"
            Icon={Cloud}
            description={
              <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[16] }}>
                <Text>
                  To get started with Garden Cloud builder you must configure your project to use it. For detailed
                  instructions, please refer to our{" "}
                  <Link to="https://docs.garden.io/v/enterprise/features/cloud-builder#configuration">
                    Cloud builder documentation
                  </Link>
                </Text>
                <Text>You will see a list of all projects that are using Cloud builder here.</Text>
              </div>
            }
          />
        ))
        .with({ status: "success" }, () => (
          <>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: tokens.spacing[56],
              }}
            >
              <BuildsTable table={table} />
            </div>
            <div css={{ display: "flex", justifyContent: "center", marginTop: tokens.spacing[12] }}>
              <Button
                disabled={!actionsQuery.hasNextPage}
                state={actionsQuery.isFetching ? "loading" : undefined}
                onClick={() => actionsQuery.fetchNextPage()}
              >
                {actionsQuery.hasNextPage ? "Load more" : "All builds loaded"}
              </Button>
            </div>
          </>
        ))
        .otherwise(() => null)}
    </Page>
  )
}

const BuildsTable = ({ table }: { table: TableDataType<any> }) => {
  const virtualLinkClickHandler = useVirtualLinkClickHandler()
  return (
    <div css={{ overflowX: "auto" }}>
      <Table
        css={{
          "tr:hover": {
            backgroundColor: tokens.colors["element-100"],
            cursor: "pointer",
          },
          "tr:hover .link-indicator": {
            display: "inherit",
          },
        }}
      >
        <thead
          css={{
            backgroundColor: tokens.colors["element-100"],
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const sorted = header.column.getIsSorted()
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    css={{
                      ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
                    }}
                  >
                    <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <Text color={sorted ? "primary" : "secondary"}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {header.id === "cloudBuilder" ? (
                        <InfoTooltip
                          id="runtime"
                          infoText="The build runtime; either Cloud builder or a fallback mechanism when it fails."
                        />
                      ) : null}
                      <Icon
                        Component={sorted === "desc" ? ArrowUp : ArrowDown}
                        title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
                        size="small"
                        css={{
                          visibility: sorted ? "visible" : "hidden",
                        }}
                      />
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody
          css={{
            "a": {
              color: tokens.colors["text-primary"],
              textDecoration: "none",
            },
            "a:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={virtualLinkClickHandler(
                commandsTab.getPath(row.original.projectId, row.original.coreSession.id) +
                  `?filter=build:${row.original.actionName}`
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  css={{
                    height: 40,
                    maxWidth: "20rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily:
                      tokens.typography[
                        cell.column.id === "builds" ? "font-family-monospace" : "font-family-sans-serif"
                      ],
                    display: cell.column.id === "status" ? "flex" : "table-cell",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
