/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */
import { css } from "@emotion/css"
import React, { useState } from "react"
import { Button } from "../../components/button"
import { Users } from "../../components/icons"
import { Input } from "../../components/input"
import { Label } from "../../components/label"
import { type GlobalPageProps, Page } from "../../components/page"
import { PageStateBase } from "../../components/page-state-base"
import { Text } from "../../components/text"
import { tokens } from "../../design-system"
import { usePermissions } from "../../utils/hooks/use-permissions"

export const CreateOrganization = (_props: GlobalPageProps) => {
  const [organizationName, setOrganizationName] = useState("")

  //TODO: make sure this is the correct permission
  const permissions = usePermissions(["orgsUpdate"])
  const userCanCreateOrganization = permissions.orgsUpdate

  const handleCreateOranization = () => {
    //TODO: handle create organization
  }

  return (
    <Page name="organization-create" scope="global">
      <PageStateBase
        title="Create a new organization"
        Icon={Users}
        description={
          <div>
            <Text>You can create as many organizations as you need.</Text>
            <Label
              text="Organization name"
              styles={{ fontWeight: 600, textAlign: "left", marginTop: tokens.spacing[40] }}
            >
              <Input
                type="text"
                value={organizationName}
                disabled={userCanCreateOrganization}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </Label>
            <Button
              onClick={handleCreateOranization}
              variant="primary"
              size="large"
              className={css({ width: "100%", marginTop: tokens.spacing[12], justifyContent: "center" })}
              disabled={organizationName === ""}
            >
              Create organization
            </Button>
          </div>
        }
        img={{
          src: "/images/flower-green.png",
          alt: "Green flower",
        }}
      />
    </Page>
  )
}
