/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { type ProjectResult } from "@garden-io/platform-api-types"
import { tokens } from "../design-system"
import { CodeSnippet } from "../ui-kit"
import { EmptyState } from "./empty-state"
import { Project as ProjectIcon } from "./icons"
import { InlineCode, Text } from "./text"

export const ProjectNotConnectedCard = ({ project }: { project: ProjectResult }) => {
  const domain = `${window.location.protocol}//${window.location.hostname}`

  const code = `id: ${project?.id ?? ""}\ndomain: ${domain}`

  return (
    <EmptyState
      Icon={ProjectIcon}
      title="Connect your project to get started"
      description={
        <>
          {project?.relativePathInRepo ? (
            <>
              To connect this project, add the following to the project-level Garden configuration file at the path{" "}
              <InlineCode styles={{ marginTop: tokens.spacing[8] }}>{project.relativePathInRepo}</InlineCode>
            </>
          ) : (
            <Text>
              To connect this project, create a project-level Garden configuration file in your repo and add the
              following:
            </Text>
          )}
          <CodeSnippet code={code} fitContent />
        </>
      }
    />
  )
}
