/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { type EnvironmentResultPartial } from "@garden-io/platform-api-types"
import { prefetchAecLogsModal } from "../../components/aec-logs-modal"
import { Button, type ButtonProps } from "../../components/button"
import { EmptyState } from "../../components/empty-state"
import { ArrowUp, Environment, Icon, Logs, Plus, Settings, Trash } from "../../components/icons"
import { Link } from "../../components/link"
import { Page, type ProjectPageProps } from "../../components/page"
import { Table } from "../../components/table"
import { Text } from "../../components/text"
import { useModals } from "../../contexts"
import { tokens } from "../../design-system"
import { getUrlWithParams } from "../../queries"
import { humanReadableSchedule, humanReadableTtl } from "../automatic-environment-cleanup/aec-settings-modal"
import { prefetchEnvironmentSettingsModal } from "./environment-settings-modal"
import { settingsPageTabs } from "./shared"

const columnHelper = createColumnHelper<EnvironmentResultPartial>()

export const EnvironmentsSettings = ({ project }: ProjectPageProps) => {
  const modals = useModals()

  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)

  const sortDesc = searchParams.get("desc")
  const sortId = searchParams.get("sort")

  const sortState = {
    desc: sortDesc === "true",
    id: sortId || "name",
  }
  const setSortState = ({ desc, id }: { desc: boolean; id: string }) => {
    const url = getUrlWithParams("", {
      sort: id,
      desc,
    })
    navigate(url)
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor((row) => row.aecSettings?.ttl, {
        id: "inactivity-schedue",
        header: () => "Inactivity schedule",
        enableSorting: false,
        cell: (info) => {
          const ttl = info.getValue()
          if (ttl) {
            return (
              <Text color="primary" styles={{ display: "flex", alignItems: "center", gap: tokens.spacing[8] }}>
                {humanReadableTtl(ttl)}
              </Text>
            )
          } else {
            return (
              <Text color="secondary" styles={{ display: "flex", alignItems: "center", gap: tokens.spacing[8] }}>
                Doesn't expire
              </Text>
            )
          }
        },
      }),
      columnHelper.accessor((row) => row.aecSettings?.schedules, {
        id: "repeat-schedue",
        header: () => "Repeat schedule",
        enableSorting: false,
        cell: (info) => {
          const schedules = info.getValue()
          const hasSchedules = schedules && schedules.length > 0
          if (hasSchedules) {
            return (
              <Text color="primary" styles={{ display: "flex", alignItems: "center", gap: tokens.spacing[8] }}>
                {humanReadableSchedule(schedules[0])}
              </Text>
            )
          } else {
            return (
              <Text color="secondary" styles={{ display: "flex", alignItems: "center", gap: tokens.spacing[8] }}>
                Not scheduled
              </Text>
            )
          }
        },
      }),
      columnHelper.display({
        id: "actions",
        enableSorting: false,
        cell: (props) => {
          return (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: tokens.spacing[6],
              }}
            >
              <Button
                Icon={Trash}
                title="Delete environment"
                variant="danger"
                size="small"
                onClick={() =>
                  modals.open({
                    type: "delete-environment",
                    projectId: project.id,
                    environmentId: props.row.original.id,
                  })
                }
              />
              <Button
                Icon={Logs}
                title="View logs"
                size="small"
                onMouseEnter={() =>
                  prefetchAecLogsModal({
                    projectId: project.id,
                    environmentId: props.row.original.id,
                  })
                }
                onClick={() =>
                  modals.open({
                    type: "aec-logs",
                    projectId: project.id,
                    environmentId: props.row.original.id,
                  })
                }
              />
              <Button
                Icon={Settings}
                title="Configure cleanups"
                size="small"
                onMouseEnter={() =>
                  prefetchEnvironmentSettingsModal({ projectId: project.id, environmentId: props.row.original.id })
                }
                onClick={() =>
                  modals.open({
                    type: "environment-settings",
                    projectId: project.id,
                    environmentId: props.row.original.id,
                  })
                }
              />
            </div>
          )
        },
      }),
    ],
    [modals, project.id]
  )

  const table = useReactTable({
    data: project.environments ?? [],
    columns,
    state: {
      sorting: [sortState],
    },
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),

    onSortingChange: (updater) => {
      const newValue = functionalUpdate(updater, [sortState])
      setSortState(newValue[0])
    },
  })

  const AddEnvironmentButton = (props: Partial<ButtonProps>) => (
    <Button
      variant="primary"
      onClick={() => modals.open({ type: "add-environment", projectId: project.id })}
      {...props}
    >
      Add environment
    </Button>
  )

  return (
    <Page
      scope="project"
      name="settings"
      tabs={settingsPageTabs(project.id)}
      action={
        project.environments.length === 0 ? null : (
          <div
            css={{
              display: "flex",
              alignItems: "center",
              marginLeft: tokens.spacing[8],
            }}
          >
            <AddEnvironmentButton Icon={Plus} />
          </div>
        )
      }
    >
      {(() => {
        if (project.environments.length === 0) {
          return (
            <EmptyState
              title="No environments yet"
              Icon={Environment}
              description={
                <>
                  <Text>
                    Read about environments in our{" "}
                    <Link to="https://docs.garden.io/guides/namespaces">documentation</Link>
                  </Text>
                  <AddEnvironmentButton />
                </>
              }
            />
          )
        }
        return (
          <Table>
            <thead css={{ backgroundColor: tokens.colors["element-100"] }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const sorted = header.column.getIsSorted()
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        css={{
                          ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
                          width: header.getSize(),
                        }}
                      >
                        <div
                          css={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: tokens.spacing[4],
                            maxHeight: tokens.spacing[24],
                          }}
                        >
                          <Text color={sorted ? "primary" : "secondary"}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </Text>

                          <Icon
                            Component={ArrowUp}
                            color="text-secondary"
                            title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
                            css={{
                              visibility: sorted ? "visible" : "hidden",
                              transform: sorted === "desc" ? "none" : "rotate(180deg)",
                            }}
                          />
                        </div>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        css={{
                          height: 40,
                          borderBottom: `1px solid ${tokens.colors["border-secondary"]}`,
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )
      })()}
    </Page>
  )
}
