/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { type UtmParams } from "common-config/dist/analytics/analytics"
import { getVcsProviderTitle } from "common-config/dist/util"
import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, LinkButton } from "../components/button"
import { Heading } from "../components/heading"
import { GitHub, GitLab, LogIn } from "../components/icons"
import { LoadingIndicator } from "../components/loading-indicator"
import { Page } from "../components/page"
import { Text } from "../components/text"
import { isGitHubEnv, useEnv, useUi } from "../contexts"
import { tokens } from "../design-system"
import { totalContextFetchDelay, useMissingValueError } from "../env"
import { Note } from "../ui-kit"
import { getGitHubAuthUrl, getGitHubAuthUrlForCli, getGitLabAuthUrl, getGitLabAuthUrlForCli } from "../utils/auth"

export const useOauthUrl = () => {
  const { cliPort } = useParams<{ cliPort: string }>()
  const env = useEnv()

  const queryParams = new URLSearchParams(window.location.search)
  const redirectTo = queryParams.get("redirectTo") ?? "/"

  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_id } = Object.fromEntries(queryParams)
  let utmParams: UtmParams | undefined = undefined

  if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || utm_id) {
    utmParams = {
      source: utm_source,
      medium: utm_medium,
      campaign: utm_campaign,
      term: utm_term,
      // NOTE: utm_id was used in a QR code for KubeCon 2023, we map this to utm_content
      // for now, but ideally remove it once the QR code is no longer in use.
      content: utm_content || utm_id,
    }
  }

  let oauthUrl: string
  if (isGitHubEnv(env)) {
    const { gitHubInstanceHostname, gitHubAppClientId, gitHubAppCallbackUrl } = env
    oauthUrl = cliPort
      ? getGitHubAuthUrlForCli({
          gitHubInstanceHostname,
          gitHubAppClientId,
          cliPort,
        })
      : getGitHubAuthUrl({
          gitHubInstanceHostname,
          gitHubAppClientId,
          gitHubAppCallbackUrl,
          redirectTo,
          utmParams,
        })
  } else {
    const { gitLabInstanceHostname, gitLabAppId } = env
    oauthUrl = cliPort
      ? getGitLabAuthUrlForCli({ instanceHostname: gitLabInstanceHostname, gitLabAppId, cliPort })
      : getGitLabAuthUrl({ instanceHostname: gitLabInstanceHostname, gitLabAppId, redirectTo })
  }
  return { vcsProvider: env.vcsProvider, oauthUrl }
}

const SSOButton = () => {
  const { cliPort } = useParams<{ cliPort: string }>()
  const queryParams = new URLSearchParams(window.location.search)
  const redirectTo = queryParams.get("redirectTo") ?? "/"

  let href = `/auth/sso/login?redirectTo=${redirectTo}`
  if (cliPort) {
    href += `&port=${cliPort}`
  }

  return (
    <Button
      className="auth"
      Icon={LogIn}
      size="large"
      variant="primary"
      css={{ a: { color: tokens.colors["pure-white"], textDecoration: "none" } }}
    >
      <a href={href}>Sign in with SSO</a>
    </Button>
  )
}

export const AuthButtons = () => {
  const { oauthUrl, vcsProvider } = useOauthUrl()
  const env = useEnv()

  return (
    <div css={{ display: "flex", alignItems: "left", flexDirection: "column", maxWidth: 800, gap: ".5rem" }}>
      {env.authUseSSO ? <SSOButton /> : null}
      {vcsProvider === "github" ? (
        <LinkButton className="auth" to={oauthUrl} openInCurrentTab Icon={GitHub} size="large" variant="primary">
          Sign in with GitHub
        </LinkButton>
      ) : vcsProvider === "gitlab" ? (
        <LinkButton className="auth" to={oauthUrl} openInCurrentTab Icon={GitLab} size="large" variant="primary">
          Sign in with GitLab
        </LinkButton>
      ) : null}
    </div>
  )
}

export const Index = () => {
  const navigate = useNavigate()
  const { dispatch: uiDispatch } = useUi()
  const { vcsProvider, authUseSSO } = useEnv()
  const identityProvider = () => {
    if (authUseSSO && getVcsProviderTitle(vcsProvider)) {
      return `SSO or ${getVcsProviderTitle(vcsProvider)}`
    } else if (authUseSSO) {
      return "SSO"
    } else {
      return getVcsProviderTitle(vcsProvider)
    }
  }

  const missingConfigError = useMissingValueError(vcsProvider, totalContextFetchDelay)

  const params = new URLSearchParams(window.location.search)
  useEffect(() => {
    const errorMessage = params.get("error")
    if (errorMessage) {
      uiDispatch({
        type: "addMessage",
        message: {
          type: "danger",
          title: errorMessage,
        },
      })
      navigate("/", { replace: true })
    }
  })

  return (
    <Page
      scope="global"
      name="index"
      styles={{ display: "flex", alignItems: "center", maxWidth: 800, margin: "0 auto" }}
    >
      <div
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: tokens.spacing[16],
          alignItems: "flex-start",
        }}
      >
        <Heading level={1}>Sign in to Garden</Heading>
        {vcsProvider ? (
          <>
            <Text color="primary" size="large" paragraph>
              Welcome! To get started with Garden, please sign in with a {identityProvider()} account for your
              Organization.
            </Text>
            <AuthButtons />
          </>
        ) : missingConfigError ? (
          <Note variant="danger">Couldn't connect to the API, please try again later.</Note>
        ) : (
          <LoadingIndicator styles={{ justifyContent: "flex-start" }} />
        )}
      </div>
    </Page>
  )
}
