/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { add, format } from "date-fns"
import React from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
// eslint-disable-next-line import/no-unresolved
import { type NameType, type ValueType } from "recharts/types/component/DefaultTooltipContent"
import {
  type InsightsTimeseriesOutcomesEntry,
  type InsightsTimeseriesTimingsEntry,
} from "@garden-io/platform-api-types"
import { tokens } from "../design-system"
import { Text } from "./text"

export const timeseriesTimingsToChartData = ({
  startsAt,
  points,
  interval = 30,
}: {
  startsAt: Date
  points?: InsightsTimeseriesTimingsEntry[]
  interval?: number
}) => {
  const result = []
  for (let i = 1; i <= interval; i++) {
    const date = add(new Date(startsAt), { days: i })

    const point = points?.find((p) => {
      // We compare month and date because the server might return slightly different values (UCT vs local time and so on)
      return new Date(p.date).getMonth() === date.getMonth() && new Date(p.date).getDate() === date.getDate()
    })
    result.push({
      date: date.getTime(),
      mean: point?.mean || 0,
      p95: point?.p95 || 0,
    })
  }
  return result
}

export const timeseriesOutcomesToChartData = ({
  startsAt,
  points,
  interval = 30,
}: {
  startsAt: Date
  points: InsightsTimeseriesOutcomesEntry[]
  interval?: number
}) => {
  const result = []
  for (let i = 1; i <= interval; i++) {
    const date = add(new Date(startsAt), { days: i })
    const point = points?.find((p) => {
      // We compare month and date because the server might return slightly different values (UCT vs local time and so on)
      return new Date(p.date).getMonth() === date.getMonth() && new Date(p.date).getDate() === date.getDate()
    })
    result.push({
      date: date.getTime(),
      success: point ? point.total - point.error : 0,
      failure: point?.error || 0,
    })
  }
  return result
}

// We iterate through all the data points and calculate the largest Y value of each point
// summing up the values for each point dataKey. The return value should be either an integer
// (in the case of summing up executions) or a float when summing up durations.
//
// We iterate each barProperty because we don't know a priori if the chart has multiple bars for each X or not.
const findLargestYFromBarProperties = (data: any[], barProperties: BarProperties[]): number => {
  let largestY = 0
  data.forEach((point) => {
    let pointY = 0
    barProperties.forEach((prop) => (pointY += point[prop.dataKey]))
    if (pointY > largestY) {
      largestY = pointY
    }
  })
  return largestY
}

export interface BarProperties {
  dataKey: string
  fill: string
  unit?: string
  stackId?: number | string
}

export const InsightsBarChart = ({
  data,
  barProperties,
  xAxisUnit,
  yAxisUnit,
  title,
  selection,
  setSelection,
  yTickFormatter,
}: {
  data: any[]
  barProperties: BarProperties[]
  xAxisUnit?: string
  yAxisUnit?: string
  title: string
  selection?: { x1: number; x2: number; selecting: boolean }
  setSelection?: (selection: { x1: number; x2: number; selecting: boolean }) => void
  yTickFormatter?: (value: any, index: number) => string
}) => {
  const largestY = findLargestYFromBarProperties(data, barProperties)

  return (
    <div
      css={{
        display: "flex",
        minHeight: "400px",
        height: "400px",
        width: "100%",
        maxWidth: "100%",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: tokens.colors["element-000"],
        gap: tokens.spacing[24],
        padding: `${tokens.spacing[16]} ${tokens.spacing[16]} 0 0`,
        marginLeft: ".-5rem",
      }}
    >
      <Text size="large" weight="bold" lineHeight="intermediate">
        {title}
      </Text>
      <ResponsiveContainer width="100%" height="100%" minWidth={550} debounce={300}>
        <BarChart
          data={data}
          onMouseDown={(e: any) => {
            if (!e?.activeLabel || !selection || !setSelection) {
              return
            }
            // Clear selection by clicking within it
            if (
              selection.x1 > -1 &&
              ((selection.x1 <= e.activeLabel && selection.x2 >= e.activeLabel) ||
                (selection.x2 <= e.activeLabel && selection.x1 >= e.activeLabel))
            ) {
              setSelection({ x1: -1, x2: -1, selecting: false })
            } else {
              setSelection({ x1: e.activeLabel, x2: -1, selecting: true })
            }
          }}
          onMouseUp={(e: any) => {
            if (!e?.activeLabel || !selection || !setSelection) {
              return
            }
            setSelection({ x1: selection.x1, x2: e.activeLabel, selecting: false })
          }}
          onMouseMove={(e: any) => {
            if (!e?.activeLabel || !selection || !setSelection) {
              return
            }
            if (selection.selecting) {
              setSelection({ x1: selection.x1, x2: e.activeLabel, selecting: true })
            }
          }}
          onMouseLeave={() => {
            if (!selection || !setSelection) {
              return
            }
            if (selection.selecting) {
              setSelection({ ...selection, selecting: false })
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Legend verticalAlign="bottom" align="right" height={36} />
          <XAxis
            dataKey="date"
            interval={7}
            tickFormatter={(value) => format(new Date(value), "MM/dd").toString()}
            unit={xAxisUnit}
            allowDataOverflow={false}
          />
          <YAxis unit={yAxisUnit} domain={[0, largestY]} allowDecimals={false} tickFormatter={yTickFormatter} />
          <Tooltip
            content={<CustomizedTooltip />}
            cursor={{
              stroke: `${tokens.colors["text-tertiary"]}`,
              strokeWidth: 1,
              fill: "rgba(0,0,0,0)",
            }}
            formatter={(value: string | number) => parseFloat(`${value}`).toFixed(3)}
          />
          {barProperties.map((bar) => (
            <Bar key={bar.dataKey} dataKey={bar.dataKey} fill={bar.fill} unit={bar.unit} stackId={bar.stackId} />
          ))}
          {selection && setSelection && (
            <ReferenceArea
              y1={0}
              y2={largestY}
              x1={selection.x1}
              x2={selection.x2}
              stroke={tokens.colors["border-secondary"]}
              fill={tokens.colors["element-300"]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export const CustomizedTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div
        css={{
          background: tokens.colors["element-000"],
          padding: `${tokens.spacing[8]} ${tokens.spacing[32]} ${tokens.spacing[8]} ${tokens.spacing[8]}`,
          border: `1px solid ${tokens.colors["border-secondary"]}`,
          borderRadius: "2px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Text css={{ marginBottom: tokens.spacing[8] }}>{`Date: ${format(new Date(label), "PP").toString()}`}</Text>
        {payload.map((item) => (
          <Text key={item.dataKey} css={{ color: item.color }}>
            {item.name}:{" "}
            {parseFloat(`${item.value}`)
              .toFixed(3)
              .replace(/[.,]000$/, "")}
            {item.unit}
          </Text>
        ))}
      </div>
    )
  }

  return null
}
