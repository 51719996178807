/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

// These are exports that are used outside of the stack-streams module. By avoiding direct imports from individual
// files in other modules consuming this one, we can more clearly definte the boundaries of the module in its
// relation to other modules.
export { StackStreamsModal, prefetchStackStreamsQueries } from "./stack-streams"
export type { TabItem } from "./stack-streams-menu"
export { StackStreamsContextProvider } from "./stack-streams-state-management"
