/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { type Env } from "@garden-io/platform-api-types"
import { useAnalytics, usePageName, useUser } from "./contexts"

export const Reporter = ({ env }: { env?: Env }) => {
  const user = useUser()
  const analytics = useAnalytics()

  const name = usePageName()

  const disabled = user?.disabled || false

  // Identify the user once its login status changes
  useEffect(() => {
    if (analytics && user?.id) {
      // Fetch the anonymousId, it should most likely exist at this
      // point unless somehow it didnt get written to local storage
      // or deleted on purpose.
      const anonymousId = localStorage.getItem("anonymousId") ?? undefined

      analytics.identify({
        userId: user.id,
        anonymousId,
        userAgent: navigator.userAgent,
        vcsUsername: user.vcsUsername!, // If a user is logged in, it cannot be a serviceAccount (which has vcsUsername set to null)
        organizationId: user.organizations[0].id, // TODO: FIX THIS for Multi-orgs
        organizationName: user.organizations[0].name, // TODO: FIX THIS for Multi-orgs
        disabled,
      })
    }
  }, [
    user?.id,
    user?.vcsUsername,
    // user?.organizations[0].id, // TODO: FIX THIS for Multi-orgs
    // user?.organizations[0].name, // TODO: FIX THIS for Multi-orgs
    user?.organizations,
    user?.name,
    user?.email,
    analytics,
    disabled,
    env,
  ])

  useEffect(() => {
    if (analytics && name) {
      const storedAnonymousIdKey = "anonymousId"
      const storedAnonymousId = localStorage.getItem(storedAnonymousIdKey)

      const storedPrevPageKey = "prevPage"
      const storedPrevPage = localStorage.getItem(storedPrevPageKey)

      const anonymousId = storedAnonymousId ?? uuidv4()

      if (!storedAnonymousId) {
        localStorage.setItem(storedAnonymousIdKey, anonymousId)
      }

      analytics.track({
        event: "Viewed Page",
        userId: user?.id,
        anonymousId: anonymousId,
        organizationName: user?.organizations[0].name, // TODO: FIX THIS for Multi-orgs
        userAgent: navigator.userAgent,
        properties: {
          name,
          url: window.location.href,
          path: window.location.pathname,
          title: document.title,
          prevPage: {
            name: storedPrevPage ? JSON.parse(storedPrevPage).name : "",
            title: storedPrevPage ? JSON.parse(storedPrevPage)?.title : "",
          },
          referrer: document.referrer,
        },
      })

      localStorage.setItem(storedPrevPageKey, JSON.stringify({ title: document.title, name: name }))
    }
    // }, [user?.id, user?.organizations[0].name, analytics, name]) // TODO: FIX THIS for Multi-orgs
  }, [user?.id, user?.organizations, analytics, name]) // TODO: FIX THIS for Multi-orgs

  return null
}
