/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { type Env } from "@garden-io/platform-api-types"
import { InternalErrorBoundary } from "./components/error-boundary"
import { LoadingIndicator } from "./components/loading-indicator"
import { Providers } from "./components/providers"
import { tokens } from "./design-system"
import { ThemeContextProvider } from "./design-system/context"
import { useEnv } from "./env"
import { Router } from "./router/router"
import { useRefreshJwtAuth } from "./utils/auth"

const Application = ({ env }: { env?: Env }) => {
  const { isLoggedIn, userId, authState } = useRefreshJwtAuth()
  if (authState === "loading") {
    return <LoadingIndicator styles={{ padding: tokens.spacing[32] }} />
  } else {
    return (
      <Providers userId={userId} env={env}>
        <React.Suspense fallback={null}>
          <Router isLoggedIn={isLoggedIn} />
        </React.Suspense>
      </Providers>
    )
  }
}

// Wrap the outermost component within an error boundary, as a fallback in the event that an error is thrown
// before the next outermost error boundary (rendered within the router).
export const App = () => {
  const env = useEnv()
  return (
    <InternalErrorBoundary errorContext="Rendering the application failed." enableReporting={!!env?.enableReporting}>
      <ThemeContextProvider>
        <Application env={env} />
      </ThemeContextProvider>
    </InternalErrorBoundary>
  )
}
